$material-design-icons-font-path: '../fonts/material-icons/' !default;
$fa-font-path:                    '../fonts/fontawesome-free' !default;

$body-bg:                           #F9F9F9 !default;
// $body-color:                        #4A4A4A !default;
// $link-color:                        #4a90e2 !default;
$text-muted:                      rgba(0,0,0,.54);

$primary:                           #1367C9 !default;
// $primary-dark:                      #123088 !default;
$success:                           #1BB40F !default;
// $dark:                              #123088 !default;
$info:                              #13C0C9 !default;
$secondary:                         #5A697B !default;
$warning:                           #EED220 !default;
$danger:                            #D52222 !default;

// $enable-shadows:                    true !default;
// $gutter:                            1rem !default;

$mdc-theme-secondary:               $primary !default;

$dark-gray:                         #2A2A2A !default;
$light-gray:                        rgba(0,0,0,.24) !default;

$theme-colors: (
//   "primary-dark": $primary-dark,
  "teal": #16BA71,
  "purple": #5163C5,
  "success": $success,
  "secondary": $secondary,
  "warning": $warning,
  "danger": $danger,
//   "error": #FF3B3B,
  "dark-gray": $dark-gray,
  "light-gray": $light-gray,
  "muted-light": #DBDFE4,
  "facebook": #3b5998,
  "twitter": #00aced,
  "vuejs": #42b883,
  "angular": #D32F2F,
  "gulp": #cf4647,
  "node": rgb(105, 159, 99),
  "gitlab": #e65328,
  "instagram": #3f729b
) !default;

// $font-size-base:                    .9375rem !default; // 15px
$font-family-base:                     'Source Sans Pro', sans-serif !default;

// $small-font-size:                   .8125rem !default;

// $headings-color:                    rgba($secondary, .84) !default;

// // $paragraph-margin-bottom:           .9375rem !default;
$bottom-spacing:                    0.9375rem * 2 !default;
$form-group-margin-bottom:          $bottom-spacing;

$border-color:                      #efefef !default;

$badge-font-size:                   .675rem !default;
$badge-font-weight:                 bold !default;
$badge-padding-y:                   .25rem !default;
$badge-padding-x:                   .25rem !default;
$badge-border-radius:               2px !default;

// $breadcrumb-padding-y:              0 !default;
// $breadcrumb-padding-x:              0 !default;
// $breadcrumb-bg:                     transparent;
// $breadcrumb-divider-color:          rgba($secondary, .24) !default;
// $breadcrumb-active-color:           rgba($secondary, .54) !default;

$table-border-color:                $border-color !default;
$table-cell-padding:                1rem !default;
// // 

// // Buttons + Forms
// //
// // Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$btn-font-weight:            600 !default;
$btn-box-shadow:             none;

$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .875rem !default;
$input-btn-font-size:         .875rem !default;


$input-btn-padding-y-sm:      .15rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      .85rem !default;

$input-btn-padding-y-lg:      .35rem !default;
$input-btn-padding-x-lg:      .85rem !default;
$input-btn-font-size-lg:      1rem !default;

// //
// $input-bg:                          #ffffff !default;
$input-border-color:                $border-color !default;
$input-placeholder-color:             rgba(0,0,0, .24) !default;
$input-focus-border-color:          $primary !default;

$input-group-addon-bg:              white !default;
$input-group-addon-color:           $dark-gray !default;

$navbar-padding-x:                  1.25rem !default;
$navbar-height:                     60px !default;
$navbar-item-spacing:               1.25rem !default;
$navbar-nav-link-padding-x:         .625rem !default;

// $navbar-light-color:                rgba(#384E68, .54) !default;
// $navbar-light-active-color:         $body-color !default;

$navbar-dark-color:                 rgba(white, .54) !default;

// $mdk-drawer-width:                  250px !default;
$layout-mini-drawer-width:          64px !default;

// $sidebar-spacing-x:                 1.25rem !default;
// $sidebar-spacing-y:                 1rem !default;

$sidebar-heading-font-size:         .6875rem !default;
$sidebar-heading-letter-spacing:    .92px !default;
$sidebar-heading-font-weight:       bold !default;
$sidebar-light-heading-color:       rgba(0,0,0,.84) !default;
$sidebar-dark-heading-color:        rgba(255,255,255,.84) !default;
// $sidebar-dark-border-color:         rgba(#ffffff, .12) !default;

$sm-icon-spacing-x:                 1.125rem !default;
$sm-button-font-weight:             600 !default;
$sm-button-font-size:                  1rem !default;
// $sm-button-font-size:               .875rem !default;

$ssm-button-height:                     1.6rem !default;
$ssm-button-font-size:                  .925rem !default;

$sm-light-button-color:             rgba(#212121, .54) !default;
$sm-light-open-bg:                  #f4f4f4 !default;
$sm-light-icon-color:               rgba(#212121, .24) !default;
$sm-light-hover-icon-color:         rgba(#212121, .84) !default;
$sm-light-active-button-color:      #212121 !default;
$sm-light-active-icon-color:        #ffffff !default;
$sm-light-toggle-color:             rgba(#212121, .24) !default;
$sm-light-hover-button-bg:          transparent !default;

// $ssm-light-button-color:            $sm-light-button-color !default;
// $sm-light-open-button-color:        #384E68 !default;
// $sm-light-open-icon-color:          $primary !default;

// $sidebar-light-container-border-color: rgba(black, .12) !default;
// $sidebar-light-border-color:        rgba(black, .12) !default;
// $sidebar-light-heading-color:       rgba($secondary, .3) !default;
$sm-dark-item-border-color:           rgba(0,0,0, .24) !default;
$sm-dark-open-bg:                     rgba(black, .24) !default;
$sm-dark-button-color:                rgba(255,255,255,.54) !default;
$sm-dark-icon-color:                rgba(255,255,255,.24) !default;
$sm-dark-active-button-color:       #ffffff !default;
$sm-dark-active-icon-color:         #ffffff !default;
$sm-dark-active-button-bg:         transparent !default;
// $sm-dark-toggle-color:              rgba(white, .24) !default;
// $sm-dark-open-toggle-color:         rgba(white, .84) !default;
// $sm-dark-open-button-color:         white !default;
$sidebar-dark-border-color:           rgba(255,255,255,.12) !default;
// // @todo: add variable to sidebar-style-guide
// $sm-dark-hover-toggle-color:        rgba(white, .24) !default;
$sm-dark-hover-button-bg:           transparent !default;
$sm-dark-hover-icon-color:           #ffffff !default;

// $ssm-dark-button-color:             rgba(white, .74) !default;
// $ssm-dark-active-button-color:      white !default;

// $sm-toggle-font-size:               20px !default;
// $sm-toggle-icon:                    "\e145" !default;
// $sm-open-toggle-icon:               "\e145" !default;

$dropdown-padding-y:                .625rem !default;
$dropdown-border-color:             $border-color !default;
$dropdown-border-radius:            5px !default;
$dropdown-box-shadow:               0 5px 11px 0 rgba(0,0,0,0.07) !default;

// $dropdown-link-color:               $dark-gray !default;
$dropdown-link-hover-color:         $primary !default;
$dropdown-link-hover-bg:            transparent !default;

$dropdown-link-active-color:        $primary !default;
$dropdown-link-active-bg:           transparent !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.25rem !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 966px
) !default;

$card-border-color:                 $border-color !default;
// $card-border-radius:                0 !default;

// $list-group-item-padding-y:         1rem !default;
$list-group-border-color:           $border-color !default;

$pagination-color:                  $primary !default;
$pagination-border-width:           0 !default;
$pagination-line-height:            30px !default;
$pagination-bg:                     $body-bg !default;
$pagination-active-bg:              $primary;

$pagination-disabled-bg:            rgba($pagination-bg, .5) !default;
$pagination-disabled-color:         rgba($text-muted, .6) !default;

// // tabs
$nav-tabs-border-width:                 0 !default;
$nav-tabs-link-active-color:            theme('primary') !default;
$nav-tabs-link-active-bg:             #fff !default;
$nav-tabs-link-active-border-color:     $border-color !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Sidebar style guide (default)
@import 'sidebar-style-guide/sass/variables';

// MDK (default)
@import 'material-design-kit/src/variables';